import { mapGetters } from "vuex";
import UserInfoHeader from "../user-info/components/user-info-header/index.vue";

export default {
    name: "distributor-orders",
    components: { UserInfoHeader },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({}),
    },
    methods: {},
};
